import { getCountry } from "./country";

const ARABIA_COUNTRIES = ["AF", "DZ", "BH", "IR", "IQ", "JO", "KW", "LB", "LY", "MA", "OM", "QA", "SA", "SD", "TJ", "AE", "TN", "EG"];
const CHINA_COUNTRIES = ["BY", "CN", "RU"];

const ARABIA_BASE_URL = "https://go.nord-cn.net/aff_c";
const CHINA_BASE_URL = "http://get.affiliatescn.net/aff_c";
const DEFAULT_BASE_URL = "https://go.nordvpn.net/aff_c";

const ARABIA_OFFER_ID = 244;
const CHINA_OFFER_ID = 177;
const DEFAULT_OFFER_ID = 30;

const countryCode = getCountry();

export function getNordVpnAdLink() {
    let baseUrl = DEFAULT_BASE_URL;
    let offerId = DEFAULT_OFFER_ID;

    // removed geo specific links for now
    // if (ARABIA_COUNTRIES.includes(countryCode)) {
    //     baseUrl = ARABIA_BASE_URL;
    //     offerId = ARABIA_OFFER_ID;
    // } else if (CHINA_COUNTRIES.includes(countryCode)) {
    //     baseUrl = CHINA_BASE_URL;
    //     offerId = CHINA_OFFER_ID;
    // }

    const urlParams = new URLSearchParams({
        aff_id: 30518,
        aff_sub: "ut",
        offer_id: offerId,
        url_id: 914,
    }).toString();
    const link = `${baseUrl}?${urlParams}`;
    return link;
}
