import React from "react"
import useTranslations from "src/utils/useTranslations"
import lanternChrome from "src/images/decoration-chrome.png"
import lanternIE from "src/images/decoration-edge.png"
import Logo from "src/components/logo"
import backgrounds from "src/components/backgrounds"
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";
import BENCH_CLIENT from "src/utils/benchClient";

const linkOsx = "https://utweb-assets.bittorrent.com/installer/uTorrentWeb.dmg"
const heightParentHeightRatio = 0.45;
const nordVpnLink = getNordVpnAdLink();

class DownloadsComplete extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			isChrome:false,
			isIE:false,
			isMac:true,
			fs:16
		}

		this.innerContainerRef = React.createRef();
	}

	adjustFs(prevFs){
		let height = this.innerContainerRef.current.clientHeight
		let parentHeight = this.innerContainerRef.current.offsetParent.clientHeight
		let fs = prevFs - .5
		if(fs <= 12) {
			return
		}
		if((height/parentHeight) > heightParentHeightRatio){
			this.setState({fs:fs})

		}
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.fs !== prevState.fs){
			this.adjustFs(this.state.fs)
		}
	}

	componentDidMount(){

		this.adjustFs(this.state.fs)

		var dlLink = linkOsx

		if(window.gatsbyIsChrome){
			this.setState({
				isChrome:true,
				isIE:false
			})
		}
		else if(window.gatsbyIsIE){
			this.setState({
				isChrome:false,
				isIE:true
			})
		}

		setTimeout(()=>{
			window.location = dlLink
		},100)

		BENCH_CLIENT.ping('load.web_download');
	}

	render(){
		const { text } = this.props.pageContext

		const t = useTranslations(text)

		var winDisplay = style.show
		var macDisplay = style.hide
		if(this.state.isMac){
			winDisplay = style.hide
			macDisplay = style.show
		}

		if(this.props.pageContext.locale === "en" || 
			this.props.pageContext.locale === "ja" ||
			this.props.pageContext.locale === "zh_tw"){

			var runInstaller = <li className="my-3">{t("Run")} <span className='font-weight-bold' style={macDisplay}>µTorrentWeb.dmg</span> {t("to install")}</li>

			var tryAgain = <>
	      		<p className="text-white ml-3" style={{...font.md, ...macDisplay}}>{t(`* If your download does not start automatically, please`)} <br/> <a href={`${linkOsx}`} className="post-download-dark">{t(`try again.`)}</a></p>
			</>
		}
		else{
			runInstaller = t(`Run µTorrentWebInstaller.pkg to install`)
			runInstaller = runInstaller.replace('µTorrentWebInstaller.pkg', `<span class='font-weight-bold'>µTorrentWeb.dmg</span>`)
			runInstaller = <li className="my-3" dangerouslySetInnerHTML={{__html: runInstaller}}></li>

			tryAgain = <>
	      		<p className="text-white ml-3" style={{...font.md, ...macDisplay}}> <a href={`${linkOsx}`} className="post-download-dark">{t(`* If your download does not start automatically, please try again.`)}</a></p>
	      		<br/>
			</>
		}
		return (
			<div className="download-complete-container top-section-container d-flex align-items-center flex-column background-noise-dark" style={backgrounds.darkBg}>
				<div className="container pt-0 px-5 mb-5 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
					<p className="text-white p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...style.headerSpacing}}>{t("Thank you for downloading")}</p>
					<div className="row">
						<div className="col-lg-6 d-flex flex-column justify-content-center">
							<Logo productName="Web" secondaryColor="white" tag="p" fsMax={26} fsCoefficient={18} className="pdlp-logo text-left ml-3"/>
							<ul className="text-white m-0 download-complete-list text-left" style={font.md}>
								<li className="my-3">{t("Wait for the download to finish*")}</li>
								<li  className="my-3">{t("Exit µTorrent Web if application is running")}</li>
								{runInstaller}
								<li className="my-3">{t("µTorrent Web will run once installed")}</li>
							</ul>
							{tryAgain}
							<p className="text-white ml-3" style={{...font.md, ...macDisplay}}>
								* {
									t(`Having trouble installing on Mac? Find the solution here.`, {
										"here": <a href="https://bittorrent.com/en/support/solutions/articles/29000034450--will-damage-your-computer-you-should-move-it-to-the-trash-" target="_blank" rel="noopener noreferrer" className="post-download-dark">{t(`here`)}</a>
									})
								}
							</p>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<a target="_blank" href={nordVpnLink} style={nordStyle}>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DownloadsComplete

const nordStyle = {
	background: 'url(/images/nord-images/affiliate-features-campaign-1500x300.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	aspectRatio: '1500/300',
	width: '100%',
	display: 'block'
};

const font = {
	md:{
		fontSize:'1.25em',
	}
}

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	},
	headerSpacing: {
		padding: '1em 0',
		margin: '1em 0'
	}
}